import React, { useState } from 'react'
import Feedback from '../../Components/Feedback'

import ceoai from '../../ceoai.png'


/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function InviteLogin({ handleLogin }) {
  const [inviteCode, setInviteCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [feedbackInfo, setFeedbackInfo] = useState({ show: false, status: '', message: '' });

  const handleDismiss = () => {
    setFeedbackInfo(prev => ({ ...prev, show: false }));
  };

  const validateCode = async() => {
    const apiUrl = 'https://login.ceo.ai'
    const action = "validate"
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inviteCode, action }),
      });
      if (response.ok) {
        const data = await response.json();
        console.log('data: ' + JSON.stringify(data))
        if (data.data.valid) {
          // handleLogin(data.token);
          console.log('success: ' + data.data.validationCode)
          await handleLogin(inviteCode, data.data.validationCode)
        } else {
          setErrorMessage('Invalid token!');
          setFeedbackInfo({ show: true, status: 'failure', message: 'Oops! Invalid invite code!'})
          console.log('not validated')
        }
      } else {
        setErrorMessage('Error validating token!');
        setFeedbackInfo({ show: true, status: 'failure', message: 'Oops! Error in transmission.'})
        console.log('Error validating token!')
      }

    } catch(error) {
      console.log(error)
    }
  }
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              className="mx-auto h-10 w-auto"
              src={ceoai}
              alt="CEO.ai"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Use Your Invite Code
            </h2>
          </div>
          <div className="space-y-6">
          {feedbackInfo.show && <Feedback status={feedbackInfo.status} onDismiss={handleDismiss} message={feedbackInfo.message} />}
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Invite Code
                </label>
                <input
                  id="invitecode"
                  name="invitecode"
                  type="text"
                  autoComplete="text"
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                  required
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
                  placeholder="Invite Code"
                />
              </div>
             
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
               
              </div>

              
            </div>

            <div>
              <button
                // type="submit"
                onClick={validateCode}
                className="flex w-full justify-center rounded-md bg-cyan-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
              >
                Validate Code
              </button>
            </div>
          </div>

          
        </div>
      </div>
    </>
  )
}
