// App.js
import React, { useState, useEffect } from 'react';
import LoginPage from './pages/Onboarding/InviteLogin';

const MainApp = React.lazy(() => import('./MainApp'));

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const saveSuccess = (inviteCode, authCode) => {
  const objectToSave = {
    type: 'authCode',
    inviteCode,
    validationCode: authCode,
    timeRun: new Date().toISOString()
  };

  try {
    localStorage.setItem('authObject', JSON.stringify(objectToSave));
    console.log('Object saved to localStorage');
    return true;
  } catch (error) {
    console.error('Error saving object to localStorage:', error);
    return false;
  }
};

  useEffect(() => {
    // Check if the authentication flag or token exists in local storage

      const authject = JSON.parse(localStorage.getItem('authObject'));
      if (authject && authject.inviteCode && authject.validationCode && authject.timeRun) {
        setIsAuthenticated(true);
      }
    
   
    
  }, []);

  const handleLogin = async(inviteCode, authCode) => {
    // Store the authentication token in db
    const success = await saveSuccess(inviteCode, authCode)
    setIsAuthenticated(true);
  };

  return (
    <div>
      {isAuthenticated ? (
        <React.Suspense fallback={<div>Loading...</div>}>
          <MainApp />
        </React.Suspense>
      ) : (
        <LoginPage handleLogin={handleLogin} />
      )}
    </div>
  );
};

export default App