import React from 'react';
import { CheckCircleIcon, XMarkIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

// Helper function to determine style and icon based on status
const feedbackStyles = (status) => {
  switch (status) {
    case 'success':
      return { bgColor: 'bg-green-50', icon: CheckCircleIcon, iconColor: 'text-green-400', textColor: 'text-green-800' };
    case 'failure':
      return { bgColor: 'bg-red-50', icon: XMarkIcon, iconColor: 'text-red-400', textColor: 'text-red-800' };
    case 'warning':
      return { bgColor: 'bg-yellow-50', icon: ExclamationCircleIcon, iconColor: 'text-yellow-400', textColor: 'text-yellow-800' };
    case 'info':
    default:
      return { bgColor: 'bg-blue-50', icon: InformationCircleIcon, iconColor: 'text-blue-400', textColor: 'text-blue-800' };
  }
};

export default function Feedback({ onDismiss, status, message }) {
  const { bgColor, icon: Icon, iconColor, textColor } = feedbackStyles(status);

  return (
    <div className={`rounded-md ${bgColor} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${textColor}`}>{message}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={onDismiss}
              className={`inline-flex rounded-md ${bgColor} p-1.5 hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white`}
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
